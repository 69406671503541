import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Empleado, EmpleadoCategoria } from "../../@core/data/cotizacion-data";
import { NbToastrService, NbWindowRef, NbWindowService } from "@nebular/theme";
import { EmpleadoService } from "../../@core/services/empleado.service";
import {
  MatTableActionConfig,
  MatTableColumnConfig,
} from "../../mat-table-builder/mat-table-builder.component";
import {
  FormFieldType,
  FormRowConfig,
  OptionType,
} from "../../form-builder/form-builder.component";
import { FormGroup } from "@angular/forms";
import { EmpleadoCategoriasService } from "../../@core/services/empleado-categorias.service";
import { Obra } from "../../@core/data/maquinaria-data";
import { ObraService } from "../../@core/services/obra.service";
import { CSVGenerator } from "../../@core/utils/csv-generator";
import { removeEmptyControls } from "../../@core/utils/forms-util";

@Component({
  selector: "ngx-empleados",
  templateUrl: "./empleados.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ["./empleados.component.scss"],
})
export class EmpleadosComponent {
  data: Empleado[] = [];
  dataSource: Empleado[] = [];
  categorias: EmpleadoCategoria[] = [];
  obras: Obra[] = [];
  @ViewChild("newDataFormView", { read: TemplateRef })
  newDataFormView: TemplateRef<HTMLElement>;
  newDataWindowRef: NbWindowRef;

  columns: MatTableColumnConfig[] = [
    {
      name: "foto",
      label: "Foto",
      value: "foto",
      type: "image",
    },
    {
      name: "nombre",
      label: "Nombre",
      value: (row: Empleado) =>
        `${row.titulo ? row.titulo : ''} ${row.nombre} ${row.apellido_paterno} ${row.apellido_materno}`,
    },
    { name: "telefono", label: "Teléfono", value: "telefono" },
    { name: "email", label: "Correo", value: "email" },
    {name : "nss", label: "Número de Seguro Social", value: "nss"},
    {name: "categoria", label: "Categoría", value: "categoria"},
    {name: "obra", label: "Obra", value: "obra"},
    {name: "tipo_conductor", label: "Licencia", value: "tipo_conductor"},
    { name: "firma", label: "Firma", value: "firma", type: "boolean" },
  ];

  acciones: MatTableActionConfig[] = [
    {
      name: 'download',
      label: 'Descargar foto',
      icon: 'download-outline',
      action: (row:Empleado) => {
        this.empleadoSerivce.downloadFoto(row.id).subscribe((data) => {
          const blob = new Blob([data], { type: 'image/jpeg' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${row.nombre}_${row.apellido_paterno}_${row.apellido_materno}.jpg`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      }
    },
    {
      name: "edit",
      label: "Editar",
      icon: "edit-2-outline",
      action: (form:FormGroup,row:Empleado,ref:NbWindowRef) => {
        this.empleadoSerivce.update(form, row.id).subscribe((data) => {
          if(form.get('foto').value){
            this.empleadoSerivce.updateFoto(row.id,form.get('foto').value).subscribe((data) => {
              this.toastrService.success("", "Foto Actualizada con exito");
            },(error)=>{
              this.toastrService.danger("", "Error al actualizar la foto");
            });
          }
          this.empleadoSerivce.getAll().subscribe((data) => {
            this.data = data;
            this.dataSource = data;
            data.forEach(e => {
               if(e.foto_path!=null){
                this.empleadoSerivce.downloadFoto(e.id).subscribe((data) => {
                  const blob = new Blob([data], { type: 'image/jpeg' });
                  const url = window.URL.createObjectURL(blob);
                  e.foto = url;
                });
              }
            });
          });
        });
        ref.close();
      },
      formConfig: (row: Empleado): FormRowConfig[] => {
        return  [
          {
            type: FormFieldType.Row,
            fields: {
              titulo: {
                formControlName: "titulo",
                placeholder: "Ejemplo: Ing. / Lic. / etc.",
                label: "Titulo",
                col: 2,
                type: FormFieldType.Input,
                value:row.titulo
              },
              empleado_categoria_id: {
                formControlName: "empleado_categoria_id",
                label: "Categoría del empleado",
                placeholder:"Categoría del empleado",
                validations: ["required"],
                col: 5,
                type: FormFieldType.Select,
                value:row.empleado_categoria_id,
                options: [
                  {key:OptionType.SelectOptions,value:
                    this.categorias?.map((cat) => ({
                      key: cat.id,
                      value: cat.nombre,
                    }))
                  }
                ]
              },
              obra_id: {
                formControlName: "obra_id",
                label: "Obra",
                placeholder: "Obra",
                validations: ["required"],
                col: 5,
                type: FormFieldType.Select,
                value:row.obra_id,
                options: [
                  {key:OptionType.SelectOptions,value:
                    this.obras?.map((obra) => ({
                      key: obra.id,
                      value: obra.nombre,
                    }))
                  }
                ]
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              nombre: {
                formControlName: "nombre",
                label: "Nombre",
                placeholder: "Nombre del empleado",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.nombre
              },
              apellido_paterno: {
                formControlName: "apellido_paterno",
                placeholder: "Apellido Paterno",
                label: "Apellido Paterno",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.apellido_paterno
                },
              apellido_materno: {
                formControlName: "apellido_materno",
                placeholder: "Apellido Materno",
                label: "Apellido Materno",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.apellido_materno
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              telefono: {
                formControlName: "telefono",
                placeholder: "Telefono del empleado",
                label: "Teléfono",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.telefono
              },
              email: {
                formControlName: "email",
                placeholder: "Correo del empleado",
                label: "Correo",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.email
              },
              nss: {
                formControlName: "nss",
                placeholder: "Número de Seguro Social",
                label: "Número de Seguro Social",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.nss
            },
          },
          },
          {
            type: FormFieldType.Row,
            fields: {
              fecha_nacimiento: {
                formControlName: "fecha_nacimiento",
                placeholder: "Fecha de Nacimiento",
                label: "Fecha de Nacimiento",
                validations: ["required"],
                col: 6,
                type: FormFieldType.Date,
                value:row.fecha_nacimiento
              },
              direccion: {
                formControlName: "direccion",
                placeholder: "Dirección",
                label: "Dirección",
                validations: ["required"],
                col: 6,
                type: FormFieldType.Input,
                value:row.direccion
              },
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              nomb_contacto_emergencia: {
                formControlName: "nomb_contacto_emergencia",
                placeholder: "Nombre de contacto de emergencia",
                label: "Nombre de contacto de emergencia",
                validations: ["required"],
                col: 6,
                type: FormFieldType.Input,
                value:row.nomb_contacto_emergencia
              },
              num_contacto_emergencia: {
                formControlName: "num_contacto_emergencia",
                placeholder: "Número de contacto de emergencia",
                label: "Número de contacto de emergencia",
                validations: ["required"],
                col: 6,
                type: FormFieldType.Input,
                value:row.num_contacto_emergencia
              },

            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              cuenta_pago: {
                formControlName: "cuenta_pago",
                placeholder: "Cuenta de pago",
                label: "Cuenta de pago",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.cuenta_pago
              },
              banco: {
                formControlName: "banco",
                label: "Banco",
                placeholder: "Banco",
                validations: ["required"],
                col: 4,
                type: FormFieldType.Input,
                value:row.banco
              },
              titular_cuenta: {
                formControlName: "titular_cuenta",
                label: "Titular de la cuenta",
                placeholder: "Titular de la cuenta",
                col: 4,
                type: FormFieldType.Input,
                value:row.titular_cuenta
              }
            },
          },
          {
            type: FormFieldType.Row,
            fields: {
              fecha_ingreso: {
                formControlName: "fecha_ingreso",
                placeholder: "Fecha de Ingreso",
                label: "Fecha de Ingreso",
                validations: ["required"],
                col: 6,
                type: FormFieldType.Date,
                value:row.fecha_ingreso
              },
              fecha_baja: {
                formControlName: "fecha_baja",
                label: "Fecha de Baja",
                placeholder: "Fecha de Baja",
                col: 6,
                type: FormFieldType.Date,
                value:row.fecha_baja
              },
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              firma: {
                formControlName: "firma",
                validations: [],
                col: 6,
                type: FormFieldType.Radio,
                label:"¿El empleado puede firmar?",
                value:row.firma,
                options:[
                  {key:OptionType.RadioOptions,value:[
                    {value:true,label:"Si"},
                    {value:false,label:"No"}
                  ]},
                ]
              },
              tipo_conductor: {
                formControlName: "tipo_conductor",
                col: 6,
                type: FormFieldType.Radio,
                label:"¿Cuenta con licencia",
                value:row.tipo_conductor,
                options:[
                  {key:OptionType.RadioOptions,value:[
                    {value:'CHOFER',label:"Chofer"},
                    {value:'OPERADOR',label:"Operador"},
                    {value:'NO',label:"No"}
                  ]},
                ]
              }
            }
          },
          {
            type: FormFieldType.Row,
            fields: {
              foto: {
                formControlName: "foto",
                validations: [],
                col: 12,
                type: FormFieldType.File,
                value:null,
                options:[
                  {key:OptionType.fileSelectTypes,value:"image/png,image/jpeg,image/jpg"},
                ]
              }
            }
          }
        ];
      },
    },
    {
      name: "delete",
      message: (row) =>
        `¿Estás seguro de eliminar el empleado ${row.nombre} ${row.apellido_paterno} ${row.apellido_materno}?`,
      title: "Eliminar Empleado",
      action: (row) => {
        this.empleadoSerivce.delete(row.id).subscribe((data) => {
          this.empleadoSerivce.getAll().subscribe((data) => {
            this.data = data;
            this.dataSource = data;
            this.data.forEach(e => {
              if(e.foto_path!=null){
               this.empleadoSerivce.downloadFoto(e.id).subscribe((data) => {
                 const blob = new Blob([data], { type: 'image/jpeg' });
                 const url = window.URL.createObjectURL(blob);
                 e.foto = url;
               });
             }
           });
            this.toastrService.success("", "Empleado Eliminado con exito");
          });
        });
      },
    },
  ];

  constructor(
    private empleadoSerivce: EmpleadoService,
    private windowService: NbWindowService,
    private toastrService: NbToastrService,
    private empleadoCategoriasService: EmpleadoCategoriasService,
    private obrasService: ObraService
  ) {
    this.empleadoSerivce.getAll().subscribe((data) => {
      this.data = data;
      this.dataSource = data;
      data.forEach(e => {
         if(e.foto_path!=null){
          this.empleadoSerivce.downloadFoto(e.id).subscribe((data) => {
            const blob = new Blob([data], { type: 'image/jpeg' });
            const url = window.URL.createObjectURL(blob);
            e.foto = url;
          });
        }
      });
    });
    this.empleadoCategoriasService.getAll().subscribe((data) => {
      this.categorias = data;
    });
    this.obrasService.getAll().subscribe((data) => {
      this.obras = data;
    });

  }
  onNewDataClick() {
    const config: FormRowConfig[] = [
      {
        type: FormFieldType.Row,
        fields: {
          titulo: {
            formControlName: "titulo",
            placeholder: "Ejemplo: Ing. / Lic. / etc.",
            label: "Titulo",
            col: 2,
            type: FormFieldType.Input,
          },
          empleado_categoria_id: {
            formControlName: "empleado_categoria_id",
            label: "Categoría del empleado",
            placeholder:"Categoría del empleado",
            validations: ["required"],
            col: 6,
            type: FormFieldType.Select,
            options: [
              {key:OptionType.SelectOptions,value:
                this.categorias?.map((cat) => ({
                  key: cat.id,
                  value: cat.nombre,
                }))
              }
            ]
          },
          obra_id: {
            formControlName: "obra_id",
            label: "Obra",
            placeholder: "Seleccione la obra",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Select,
            options: [
              {key:OptionType.SelectOptions,value:
                this.obras?.map((obra) => ({
                  key: obra.id,
                  value: obra.nombre,
                }))
              }
            ]
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          nombre: {
            formControlName: "nombre",
            label: "Nombre",
            placeholder: "Nombre del empleado",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Input,
          },
          apellido_paterno: {
            formControlName: "apellido_paterno",
            placeholder: "Apellido Paterno",
            label: "Apellido Paterno",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Input,
          },
          apellido_materno: {
            formControlName: "apellido_materno",
            placeholder: "Apellido Materno",
            label: "Apellido Materno",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Input,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          telefono: {
            formControlName: "telefono",
            placeholder: "Telefono del empleado",
            label: "Teléfono",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Input,
          },
          email: {
            formControlName: "email",
            placeholder: "Correo del empleado",
            label: "Correo",
            col: 4,
            type: FormFieldType.Input,
          },
          nss: {
            formControlName: "nss",
            placeholder: "Número de Seguro Social",
            label: "Número de Seguro Social",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Input,
          }
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          fecha_nacimiento: {
            formControlName: "fecha_nacimiento",
            placeholder: "Fecha de Nacimiento",
            label: "Fecha de Nacimiento",
            validations: ["required"],
            col: 6,
            type: FormFieldType.Date,
          },
          direccion: {
            formControlName: "direccion",
            placeholder: "Dirección",
            label: "Dirección",
            validations: ["required"],
            col: 6,
            type: FormFieldType.Input,
          },
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          nomb_contacto_emergencia: {
            formControlName: "nomb_contacto_emergencia",
            placeholder: "Nombre de contacto de emergencia",
            label: "Nombre de contacto de emergencia",
            validations: ["required"],
            col: 6,
            type: FormFieldType.Input,
          },
          num_contacto_emergencia: {
            formControlName: "num_contacto_emergencia",
            placeholder: "Número de contacto de emergencia",
            label: "Número de contacto de emergencia",
            validations: ["required"],
            col: 6,
            type: FormFieldType.Input,
          },

        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          cuenta_pago: {
            formControlName: "cuenta_pago",
            placeholder: "Cuenta de pago",
            label: "Cuenta de pago",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Input,
          },
          banco: {
            formControlName: "banco",
            label: "Banco",
            placeholder: "Banco",
            validations: ["required"],
            col: 4,
            type: FormFieldType.Input,
          },
          titular_cuenta: {
            formControlName: "titular_cuenta",
            label: "Titular de la cuenta",
            placeholder: "Titular de la cuenta",
            col: 4,
            type: FormFieldType.Input,
          }
        },
      },
      {
        type: FormFieldType.Row,
        fields: {
          fecha_ingreso: {
            formControlName: "fecha_ingreso",
            placeholder: "Fecha de Ingreso",
            label: "Fecha de Ingreso",
            validations: ["required"],
            col: 6,
            type: FormFieldType.Date,
          },
          fecha_baja: {
            formControlName: "fecha_baja",
            label: "Fecha de Baja",
            placeholder: "Fecha de Baja",
            col: 6,
            type: FormFieldType.Date,
          },
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          firma: {
            formControlName: "firma",
            validations: [],
            col: 6,
            type: FormFieldType.Radio,
            label:"¿El empleado puede firmar?",
            value:false,
            options:[
              {key:OptionType.RadioOptions,value:[
                {value:true,label:"Si"},
                {value:false,label:"No"}
              ]},
            ]
          },
          tipo_conductor: {
            formControlName: "tipo_conductor",
            col: 6,
            type: FormFieldType.Radio,
            label:"¿Cuenta con licencia?",
            value:'NO',
            options:[
              {key:OptionType.RadioOptions,value:[
                {value:'CHOFER',label:"Chofer"},
                {value:'OPERADOR',label:"Operador"},
                {value:'NO',label:"No"}
              ]},
            ]
          }
        }
      },
      {
        type: FormFieldType.Row,
        fields: {
          foto: {
            formControlName: "foto",
            validations: [],
            col: 12,
            type: FormFieldType.File,
            value:null,
            options:[
              {key:OptionType.fileSelectTypes,value:"image/png,image/jpeg,image/jpg"},
            ]
          }
        }
      }

    ];
    this.newDataWindowRef = this.windowService.open(this.newDataFormView, {
      title: "Nuevo Empleado",hasBackdrop: false,
      context: { config: config },
    });
  }

  filter(value: string) {
    const filterValue = value.toLowerCase();
    this.dataSource = this.data.filter((option) =>
      option.titulo?.toLowerCase().includes(filterValue)||
      option.nombre?.toLowerCase().includes(filterValue)||
      option.apellido_paterno?.toLowerCase().includes(filterValue)||
      option.apellido_materno?.toLowerCase().includes(filterValue)||
      option.telefono?.toLowerCase().includes(filterValue)||
      option.email?.toLowerCase().includes(filterValue)||
      option.obra?.toLowerCase().includes(filterValue)||
      option.tipo_conductor?.toLowerCase().includes(filterValue)||
      option.nss?.toLowerCase().includes(filterValue)||
      option.categoria?.toLowerCase().includes(filterValue)
    );
  }

  onSave() {
    this.empleadoSerivce.getAll().subscribe((data) => {
      this.data = data;
      this.dataSource = data;
      data.forEach(e => {
        if(e.foto_path!=null){
         this.empleadoSerivce.downloadFoto(e.id).subscribe((data) => {
           const blob = new Blob([data], { type: 'image/jpeg' });
           const url = window.URL.createObjectURL(blob);
           e.foto = url;
         });
       }
     });
      this.newDataWindowRef.close();
      this.toastrService.success("", "Empleado Guardado con exito");
    });
  }
  onFormOutput(form:FormGroup){
    removeEmptyControls(form)
    this.empleadoSerivce.create(form).subscribe((data) => {
      this.empleadoSerivce.getAll().subscribe((data) => {
        this.data = data;
        this.dataSource = data;
        data.forEach(e => {
          if(e.foto_path!=null){
           this.empleadoSerivce.downloadFoto(e.id).subscribe((data) => {
             const blob = new Blob([data], { type: 'image/jpeg' });
             const url = window.URL.createObjectURL(blob);
             e.foto = url;
           });
         }
       });
        this.newDataWindowRef.close();
        this.toastrService.success("", "Empleado Guardado con exito");
      });
    },error=>{
      this.toastrService.danger(error.message, "Error al guardar el empleado");
    });
  }
  onDownloadReportClick(){
    const report_config = [

      {
        name: "nombre",
        label: "Nombre",
        value: (row: Empleado) =>
          `${row.titulo ? row.titulo : ''} ${row.nombre} ${row.apellido_paterno} ${row.apellido_materno}`,
      },
      { name: "telefono", label: "Teléfono", value: "telefono" },
      { name: "email", label: "Correo", value: "email" },
      {name : "nss", label: "Número de Seguro Social", value: "nss"},
      {name: "categoria", label: "Categoría", value: "categoria"},
      {name: "obra", label: "Obra", value: "obra"},
      {name: "tipo_conductor", label: "Licencia", value: "tipo_conductor"},
      { name: "firma", label: "Firma", value: "firma", type: "boolean" },
    { name: "fecha_nacimiento", label: "Fecha de Nacimiento", value: (row:Empleado)=>new Date(row.fecha_nacimiento).toLocaleDateString() },
    { name: "direccion", label: "Dirección", value: "direccion" },
    { name: "num_contacto_emergencia", label: "Número de Contacto de Emergencia", value: "num_contacto_emergencia" },
    { name: "cuenta_pago", label: "Cuenta de Pago", value: "cuenta_pago" },
    { name: "banco", label: "Banco", value: "banco" },
    { name: "fecha_ingreso", label: "Fecha de Ingreso", value: (row:Empleado)=>new Date(row.fecha_ingreso).toLocaleDateString() },
    { name: "fecha_baja", label: "Fecha de Baja", value: (row:Empleado)=>row.fecha_baja?new Date(row.fecha_baja).toLocaleDateString():"" },
    { name: "nomb_contacto_emergencia", label: "Nombre de Contacto de Emergencia", value: "nomb_contacto_emergencia" },
    { name: "titular_cuenta", label: "Titular de la Cuenta", value: "titular_cuenta" },
    ] as MatTableColumnConfig[];
    CSVGenerator.generateExcelWithCustomBooleanMessages(report_config,this.dataSource,"empleados",{firma:["SI", "No"]});
  }
}
