import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FormGroup } from "@angular/forms";
import { HttpHandler } from "@angular/common/http";
@Injectable()
export class BaseService<T> {
  protected baseUrl: string = "https://back.grupocostamarq.com.mx/api/";
  //protected baseUrl: string = "api/";

  protected http: HttpClient;

  constructor(httpHandler: HttpHandler) {
    this.http = new HttpClient(httpHandler);
  }

  // Método protegido que las subclases pueden sobrescribir para especificar su path
  protected getPath(): string {
    return ''; // Retorna un string vacío por defecto, o podrías lanzar un error para forzar la sobreescritura
  }

  public getAll(): Observable<T[]> {
    const url = `${this.baseUrl}${this.getPath()}`;
    return this.http.get<T[]>(url);
  }

  public getAllBy(attributes: { [key: string]: any }[]): Observable<T[]> {
    const url = `${this.baseUrl}${this.getPath()}?${attributes.map(attr => Object.keys(attr).map(key => `${key}=${attr[key]}`).join('&')).join('&')}`;
    return this.http.get<T[]>(url);
  }

  public searchByText(text: string): Observable<T[]> {
    const url = `${this.baseUrl}${this.getPath()}?search=${text}`;
    return this.http.get<T[]>(url);
  }



  public getById(id: number): Observable<T> {
    const url = `${this.baseUrl}${this.getPath()}/${id}`;
    return this.http.get<T>(url);
  }

  public create(formGroup: FormGroup,formadDate:boolean = false): Observable<T> {
    const url = `${this.baseUrl}${this.getPath()}`;
    const rawValue = formGroup.getRawValue();

   if(formadDate){
     // Convert date fields to local date
     for (const key in rawValue) {
      if (rawValue[key] instanceof Date) {
        rawValue[key] = rawValue[key].toLocaleDateString();
      }
    }
   }

    return this.http.post<T>(url, rawValue);
  }

  public update(formGroup: FormGroup,id:any): Observable<T> {
    const url = `${this.baseUrl}${this.getPath()}/${id}`;
    return this.http.put<T>(url, formGroup.getRawValue());
  }
  public delete(id: number): Observable<T> {
    const url = `${this.baseUrl}${this.getPath()}/${id}`;
    return this.http.delete<T>(url);
  }
}

export class TokenResponse {
  token: string;
  message: string;
  user: any;
}

export class BasicResponse<T> {
  message: string;
  data: T;
}

export class TimeStamp {
  created_at: Date;
  updated_at: Date;
}



